import { GetServerSideProps } from "next";
import Head from "next/head";
import React, { FC, useEffect } from "react";

import extendSectionsData from "@/dep_helpers/content/extendSectionsData";
import getHomepageData from "@/dep_helpers/content/pages/home/getHomepageData";
import EventLocationType from "@/dep_types/EventLocationType";
import { ContentErrorType } from "@/dep_types/content/error";
import { HomepageType } from "@/dep_types/content/homepage";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import DynamicContent from "@/dep_components/DynamicContent/DynamicContent";
import HeroImage from "@/dep_components/Sections/HeroImage";
import { useArriveContext } from "@/dep_components/context";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import Meta from "../dep_components/Header/Meta";
import { getCurrentPartnerFromHost } from "../dep_constants/partnerIds";
import TrackingStrategy from "../dep_services/tracking/stratagies/trackingStrategy";
import TrackingService from "../dep_services/tracking/trackingService";

const { COMPONENT_KEYS } = commonKeys;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req, preview = false, query } = context;

  const { partnerId } = getCurrentPartnerFromHost(req.headers.host);

  const homepageData = await getHomepageData(partnerId, preview);

  if (homepageData.data) {
    homepageData.data.sections = await extendSectionsData(
      partnerId,
      homepageData.data.sections,
    );
  }

  return {
    props: {
      seo: homepageData.data?.seo || null,
      hero: homepageData.data?.hero || null,
      sections: homepageData.data?.sections || null,
      contentError: homepageData.error,
      partnerId,
      openCart: query.opencart || false,
    },
  };
};

type Props = HomepageType & {
  contentError: ContentErrorType;
  canonicalDomain: string;
  openCart: boolean;
};

const Home: FC<Props> = ({
  seo,
  hero,
  sections,
  canonicalDomain,
  openCart,
}: Props) => {
  const { googleSiteVerificationId, fbDomainVerificationId } =
    useWhitelabelContext();

  const { openShoppingCart } = useArriveContext();

  useEffect(() => {
    if (TrackingStrategy.partnerId) {
      TrackingService.trackPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TrackingStrategy.partnerId]);

  useEffect(() => {
    if (openCart) {
      openShoppingCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCart]);

  const extraProps = {
    [COMPONENT_KEYS.RECOMMENDED_PRODUCTS]: {
      eventLocation: EventLocationType.HOME,
      eventName: "Favorite Products Slide Changed",
    },
  };

  return (
    <div color="black">
      <Head>
        {googleSiteVerificationId && (
          <meta
            name="google-site-verification"
            content={googleSiteVerificationId}
          />
        )}
        {fbDomainVerificationId && (
          <meta
            name="facebook-domain-verification"
            content={fbDomainVerificationId}
          />
        )}
      </Head>
      <Meta
        canonicalDomain={canonicalDomain}
        title={seo.title}
        description={seo.description}
      />

      <HeroImage {...hero} />
      {sections.map((section) => (
        <DynamicContent
          key={`${section.componentKey}-${section.id}`}
          componentData={section}
          extraProps={extraProps}
        />
      ))}
    </div>
  );
};

export default Home;
