import Head from "next/head";
import { useRouter } from "next/router";
import React, { FC } from "react";

type Props = {
  canonicalDomain: string;
  title?: string;
  description?: string;
  imageUrl?: string;
};

const Meta: FC<Props> = ({
  canonicalDomain,
  title,
  description,
  imageUrl,
}: Props) => {
  const router = useRouter();

  const url = router.asPath;
  const hostLength = Math.min.apply(Math, [
    url.indexOf("?") > 0 ? url.indexOf("?") : url.length,
    url.indexOf("#") > 0 ? url.indexOf("#") : url.length,
  ]);
  const canonicalUrl = url.substring(0, hostLength);

  const canonicalURL = canonicalDomain + canonicalUrl;

  return (
    <Head>
      <title>{title}</title>
      <link rel="canonical" href={canonicalURL} />
      <meta property="og:type" content="website" key="og:type" />
      <meta property="og:title" content={title} key="og:title" />
      <meta name="twitter:title" content={title} key="twitter:title" />
      {description && (
        <>
          <meta name="description" content={description} key="description" />
          <meta
            name="og:description"
            content={description}
            key="og:description"
          />
          <meta
            name="twitter:description"
            content={description}
            key="twitter:description"
          />
        </>
      )}
      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} key="og:image" />
          <meta
            name="twitter:card"
            content="summary_large_image"
            key="twitter:card"
          />

          <meta name="twitter:image" content={imageUrl} key="twitter:image" />
        </>
      )}
    </Head>
  );
};

Meta.defaultProps = {
  title: undefined,
  description: undefined,
  imageUrl: undefined,
};
// eslint-disable-next-line import/no-default-export
export default Meta;
